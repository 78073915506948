<!--产学研对接列表-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp; 产学研对接
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :xs="24" :sm="24" :md="22" :lg="20" class="el-col_p0">
        <div class="reslist">
            <ul>
                <li v-for="(item,index) in listData" :key="index">
                  <div class="res">                    
                    <el-row :gutter="0">
                      <el-col :sm="6" :md="6">
                        <router-link :to="{ path: '/activity/activity', query: { id: item.id }}" target="_blank">
                          <!-- <img :src='showImage(item.userId)' /> -->
                          <el-image :src="showImage(item.userId)" fit="scale-down" class="res-img" />
                        </router-link>
                      </el-col>
                      <el-col :sm="18" :md="18">
                        <div class="res-tit"><router-link :to="{ path: '/activity/activity', query: { id: item.id }}" target="_blank">{{item.name.substring(0,30)}}</router-link></div>
                        <div class="res-intro1">
                          <p>
                            <span>报名开始时间：<time>2022-07-13 09:00</time></span> <span>额定报名人数：<time>3人/企业</time></span> 
                          </p>
                           <p>
                            <span>报名截至时间：<time>2022-08-24 18:00</time></span> <span>活动剩余名额：<time>23人</time></span> 
                          </p>
                          <p>
                            <span>活动开始时间：<time>2022-08-26 09:00:00</time></span> <span>活动总计名额：<time>50人</time></span> 
                          </p>
                          <p>
                            <span>活动举办地点：昆明高新区中小企业公共服务平台（高新区昌源北路1389号航空航天及IT大厦A座3楼）</span> 
                          </p>
                        </div>
                        <!-- <div class="res-but">
                          <a :href="`/activity/activity?id=${item.id}`" target="_blank"><el-button type="primary" plain>立即报名</el-button></a>
                        </div> -->
                      </el-col>
                    </el-row>
                  </div>
                </li>
            </ul>
        </div>
        <div class="pagination">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
              :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
            </el-pagination>
        </div>        
      </el-col>
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
    </el-row>
  </div> 
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: 'resourcelist',  
  data () {
    return {
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      listData: [],
      // listData: [{"name":"物理实验室", "intro"}]
    }
  },
  mounted () {
    this.getListData()
  },
  methods: {
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    async getListData () {
      // status=1&
      const params = '?size='+ this.pagesize + '&current=' + this.currentPage
      const { data: ret } = await this.$http.get1(this.$api.resourcelist + params)      
      // console.log('ret=', ret)
      if (ret.code === 1) {
        this.listData = ret.data.records
        this.tableTotal = ret.data.total
        // console.log('rows=', this.messageData)
      } else {
        this.$message.error(ret.msg)        
      }     
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){      
      return formatDate(time,'yyyy-MM-dd')
    },
    showImage(val){
      if(val === null) {        
        return '/images/nopic1.jpg'
      } else {        
        var arr = JSON.parse(val)
        var imgurl = this.$api.serverUrl + arr[0].url
        // console.log('IMAGE_URL=', imgurl)       
        return imgurl
      }
    }
  }
}
</script>

<style scoped>
</style>